<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card card m-b-0">
                    <div class="chat-main-box">
                        <!-- left-panel -->
                        <div class="chat-left-aside">
                            <div class="open-panel">
                                <i class="ti-angle-right"></i>
                            </div>
                            <div class="chat-left-inner form-horizontal" style="height: 3px;">
                                <div class="form-material">
                                    <div class="input-group searchbox">
                                        <input type="text" class="form-control" id="uname" placeholder="Pencarian..."
                                            v-model="postUIFlag.searchTerm" @input="isTyping = true" />
                                        <div class="input-group-addon">
                                            <i class="ti-search"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center" id="contact-loader-indicator" v-bind:class="{'d-none': !postUIFlag.isLoading}">
                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </div>
                                <div id="left-inner-scrollbar" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none;">
                                    <div class="scroll-content">
                                        <ul class="chatonline style-none">
                                            <li v-for="item in postList" :key="item._id">
                                                <router-link v-bind:class="{ active: item._id == postDetail._id }" :to="{ name: 'comments-ig', params: {id: encodeURIComponent(item._id)}}">
                                                    <div class="wrapp-img">
                                                        <span v-if="item.mediaUrl">
                                                            <img :src="item.mediaUrl" alt="user-img" class="img-circle" />
                                                        </span>
                                                        <span v-else>
                                                            <div class="initials">
                                                                <i class="mdi mdi-instagram"></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span class="chat-info"> 
                                                        <span class="user-name" title="Caption">
                                                            {{ item.message }}
                                                        </span>
                                                        <small class="text-default">
                                                            <div class="chat-content-thumb" title="Tanggal unggah">
                                                                {{ utcToLocal(item.createdOn, 'DD-MM-YYYY') }}
                                                            </div>
                                                        </small>
                                                    </span>
                                                    <span class="right-content-chat user-info">
                                                        <small class="chat_time" title="Jam terakhir komen" v-if="item.time">
                                                            {{ item.time }}
                                                        </small>
                                                        <span v-if="item.countIsNotReply && item.countIsNotReply > 0"
                                                            title="Jumlah komen belum dibalas"
                                                            class="badge badge-pill badge-primary text-white">
                                                            {{ item.countIsNotReply }}
                                                        </span>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </router-link>
                                            </li>
                                            <li class="text-center" id="user-loader-indicator" v-if="postUIFlag.isLoading">
                                                <span class="chat-info"> <span class="user-info">Load More...</span>
                                                    <small class="text-default">
                                                        <div class="chat-content-thumb"></div>
                                                    </small>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                        <div class="scrollbar-thumb scrollbar-thumb-x" style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                    </div>
                                    <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                        <div class="scrollbar-thumb scrollbar-thumb-y" style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- left-panel -->
                        <!-- right-panel -->
                        <div class="chat-right-aside">
                            <div class="chat-main-header">
                                <div class="headchat head-comment" v-if="postDetail.message">
                                    <div class="pull-left left-side">
                                        <span>
                                            <div class="wrapp-img" @click="viewImage($event)" style="cursor: pointer">
                                                <span v-if="postDetail.mediaUrl">
                                                    <img :src="postDetail.mediaUrl" alt="user-img" />
                                                </span>
                                                <span v-else>
                                                    <div class="initials">
                                                        <i class="mdi mdi-instagram"></i>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class="chat-header">
                                                <a class="uname" :href="postDetail.linkUrl" target="_blank" rel="noopener noreferrer">
                                                    {{ postDetail.message }}
                                                </a>
                                                <br>
                                                <span>
                                                    {{ utcToLocal(postDetail.createdOn, 'DD-MM-YYYY') }}
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-rbox">
                                <div id="chatContent" class="wrapp-chat-content" data-scrollbar="true" tabindex="1" style="overflow: hidden; outline: none; width:100%">
                                    <div class="scroll-content">
                                        <ul class="list-chat">
                                            <li v-for="(item, index) in commentList" :class="{ 'gap-chat': !item.isChild || item.isShowReply }" :key="item._id">
                                                <div class="chat-content" :class="{'ml-5': item.isChild}" v-if="!item.isChild || item.isShowReply">
                                                    <div v-if="!item.isChild" :class="chatItemStyle(item.url, 'cust')">
                                                        <div class="is-read-comment mt-2 mr-2" @click="markReadComment(index, item)" v-if="item.isReplied == false">
                                                            <i class="mdi mdi-email-outline text-primary" title="mark read"
                                                                style="font-size: 16px;"></i>
                                                        </div>
                                                        <span class="text-info font-weight-bold">
                                                            {{ item.from.name }}
                                                        </span>
                                                        <span v-if="item.isReplied == false" class="mr-3">
                                                            <i class="mdi mdi-information text-warning" style="font-size: 14px;"
                                                                title="Komen belum dibalas"></i>
                                                        </span>
                                                        <div class="pull-left caption-img">
                                                            <div v-html="item.message"></div>
                                                        </div>
                                                        <div class="pull-left text-left mt-2">
                                                            <div class="chat-time pull-left">
                                                                <span class="mr-3">
                                                                    <i class="mr-1 ti-time" title="Waktu"></i>
                                                                    {{ item.time }}
                                                                </span>
                                                                <button class="mr-3" @click="showReply(item)">
                                                                    {{ item.isReply ? 'Tutup' : 'Balas' }} 
                                                                </button>
                                                                <button class="mr-3" v-if="item.sub && item.sub.length > 0"
                                                                    @click="showRepliedComments(item._id, !item.isShowReply)">
                                                                    {{ item.isShowReply ? 'Tutup' : 'Tampilkan' }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="item.isShowReply" :class="chatItemStyle(item.url, 'op')">
                                                        <span class="text-info font-weight-bold">
                                                            {{ item.from.name }}
                                                        </span>
                                                        <div class="pull-left caption-img">
                                                            <div v-html="item.message"></div>
                                                        </div>
                                                        <div class="pull-left text-left mt-2">
                                                            <div class="chat-time pull-left">
                                                                <span class="mr-3">
                                                                    <i class="mr-1 ti-time" title="Waktu"></i>
                                                                    {{ item.time }}
                                                                </span>
                                                                <button class="mr-3" @click="item.isReply = !item.isReply">
                                                                    {{ item.isReply ? 'Tutup' : 'Balas' }} 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="item.isReply">
                                                        <!-- <textarea 
                                                            @keyup.enter="handleEnterComment($event, item)"
                                                            v-model="item.text"
                                                            style="height: 35px;"
                                                            class="form-control b-0 mt-2"
                                                            placeholder="Balas komentar disini..."
                                                            :disabled="item.isLoading"
                                                        ></textarea>
                                                        <button title="Balas Komentar"
                                                            class="btn btn-primary pull-right"
                                                            @click="handleEnterComment($event, item)"
                                                            :disabled="item.isLoading"
                                                        >
                                                            <i v-if="item.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                                            <i v-else class="fa fa-paper-plane"></i>
                                                        </button> -->
                                                        <div class="m-t-10 input-group m-b-10">
                                                            <input type="text" class="form-control" placeholder="Balas komentar disini..."  @keyup.enter="handleEnterComment($event, item)"
                                                            v-model="item.text"
                                                                                                              
                                                            :disabled="item.isLoading">
                                                            <div class="input-group-append">
                                                                <button title="Kirim Pesan" @click="handleEnterComment($event, item)"
                                                            :disabled="item.isLoading" class="btn btn-primary" type="button">
                                                             <i v-if="item.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                                            <i v-else class="fa fa-paper-plane"></i>
                                                            </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="!item.isShowReply && !item.isChild && item.sub && item.sub.length > 0" class="mt-2 ml-2">
                                                        <a @click="showRepliedComments(item._id, !item.isShowReply)">
                                                            <u><b>{{ item.sub.length }} Balasan</b></u>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li id="contact-loader-indicator" class="text-center" v-bind:class="{ 'd-none': !commentUiFlag.isLoading }">
                                                <b-spinner variant="secondary" label="Loading..."></b-spinner>
                                            </li>
                                        </ul>
                                        <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                            <div class="scrollbar-thumb scrollbar-thumb-x"
                                                style="width: 728px; transform: translate3d(0px, 0px, 0px);"></div>
                                        </div>
                                        <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                            <div class="scrollbar-thumb scrollbar-thumb-y"
                                                style="height: 61.2129px; transform: translate3d(0px, 0px, 0px);"></div>
                                        </div>
                                    </div>
                                    <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                        <div class="scrollbar-thumb scrollbar-thumb-x"
                                            style="width: 605px; transform: translate3d(0px, 0px, 0px);"></div>
                                    </div>
                                    <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                        <div class="scrollbar-thumb scrollbar-thumb-y"
                                            style="height: 422.828px; transform: translate3d(0px, 0px, 0px);"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="wrapp-input-text" v-if="postDetail._id">
                                <div>
                                    <div class="input-group group-text-input input-area-chat">
                                        <textarea id="inputor" 
                                            style="height: 35px;" 
                                            :disabled="newMessage.isLoading"
                                            v-model="newMessage.messageText" 
                                            placeholder="Buat komentar disini.."
                                            class="form-control b-0" 
                                            @keyup.enter="handleEnterMessage">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="clearfix m-t-30"></div>
                                <div class="m-t-5">
                                    <div class="pull-left attach-list">
                                        <b-dropdown no-caret title="Emoticon" class="dropup-select megaDropdown">
                                            <template v-slot:button-content>
                                                <i class="fa fa-smile-o"></i>
                                            </template>
                                            <div class="p-10">
                                                <b-tabs content-class="mt-3">
                                                    <b-tab v-for="(item, index) in emoticonList" :key="index"
                                                        :title="item.icon">
                                                        <span class="cursor-pointer" v-for="(emoji, i) in item.emojis"
                                                            :key="i" @click="newMessage.messageText += emoji"
                                                            style="font-size: 20px;">
                                                            {{ emoji }}
                                                        </span>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                    <div class="pull-right">
                                        <button title="Balas Komentar"
                                            class="btn btn-primary pull-right"
                                            @click="handleButtonMessage($event)" 
                                            :disabled="newMessage.isLoading"
                                        >
                                            <i v-if="newMessage.isLoading" class="fa fa-circle-o-notch fa-spin"></i>
                                            <i v-else class="fa fa-paper-plane"></i> Balas
                                        </button>
                                        <button class="btn btn btn-default create-contact m-r-10 pull-right">
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- right-panel -->
                    </div>
                </div>
            </div>
        </div>

        <ImageViewer v-bind:imageUrl="postDetail.mediaUrl" v-bind:caption="postDetail.message" />
    </div>
</template>

<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    import MainLayout from "../layouts/MainLayout"
    import EMOTICON from '../utils/emoticon'
    import ImageViewer from '../components/ImageViewer'

    import { mapState, mapActions } from 'vuex'

    const $ = require("jquery")
    const moment = require('moment');
    const debounce = require('lodash.debounce')

    const { commonpagesetup, mainuisetup } = require('../utils/ui-setup')
    const { toFriendlyDateTime } = require('../utils/date-utils');

    var contactScrollBar = null
    var messageScrollBar = null;

    export default {
        components: {
            ImageViewer
        },

        data: function() {
            return {
                postList: Array(),
                commentList: Array(),
                emoticonList: Array(),

                postDetail: Object(),
                selectedChannel: Object(),
                selectedImage: Object(),

                isTyping: false,

                newMessage: {
                    messageText: String(),
                    isLoading: false
                },

                postUIFlag: {
                    currentPage: 1,
                    sortOrder: 'lastCommentTime',
                    sortDir: 'desc',
                    searchTerm: String(),
                    error: Object(),
                    hasMoreData: true,
                    isLoading: false
                },

                commentUiFlag: {
                    currentPage: 1,
                    sortOrder: 'createdOn',
                    sortDir: 'desc',
                    error: Object(),
                    hasMoreData: true,
                    isLoading: false
                },
            }
        },

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: function() {
            Scrollbar.initAll();
            contactScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);
            messageScrollBar = Scrollbar.get($('.wrapp-chat-content').get()[0]);

            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
                
                this.actGetCommentCount()
            }
            
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }

            this.emoticonList = EMOTICON
        },

        watch: {
            'postUIFlag.searchTerm': debounce(function () {
                this.isTyping = false;
            }, 1000),
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectPost(decodeURIComponent(to.params.id));
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            isTyping: function (value) {
                if (!value) {
                    this.loadPosts();
                }
            }
        },

        methods: {
            ...mapActions({
                initOperatorList: 'operator/initOperatorList',
                actGetCommentCount: 'getCommentCount'
            }),
            viewImage: function (event) {
                event.preventDefault();
                this.$bvModal.show("modal-image-preview");
            },
            showReply: function(item) {
                item.isReply = !item.isReply

                if(item.isReply) this.showRepliedComments(item._id, true)
            },
            showRepliedComments: function(id, val) {
                this.commentList = this.commentList.map(e => {
                    if(this.selectedChannel.fbmsgr_ig_business_account_id != e.from.id) e.text = "@"+ e.from.name + " "
                    
                    if(e._id == id) {
                        e.isShowReply = val
                    }

                    if(e.isChild && e.parentId == id) {
                        e.isShowReply = val

                        return e
                    }

                    return e
                })   
            },
            markReadComment: async function(index, item) {
                try {
                    await axios.put(process.env.VUE_APP_API_URL + `/mark-comments/${item._id}`, Object(), {
                        params: {
                            postId: item.postId
                        }
                    }) 

                    this.commentList[index].isReplied = true
                    this.postDetail.countIsNotReply = this.postDetail.countIsNotReply - 1
                } catch (error) {
                    console.log("error-mark-read", error)
                }
            },
            handleButtonMessage: async function(e) {
                try {
                    this.newMessage.isLoading = true

                    const body = {
                        channelId: this.postDetail.channelId,
                        contentType: "text",
                        postId: this.postDetail._id,
                        message: this.newMessage.messageText
                    }

                    const data = await this.sendMessage(e, body.postId, false, this.postDetail.channel, body)

                    this.commentList = [this.formatComment(data), ...this.commentList]
                    this.newMessage.messageText = String()

                    this.updatePostList(body.postId, true)
                    this.newMessage.isLoading = false

                    messageScrollBar.update();
                    const self = this
                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, 0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                    })
                } catch (error) {
                    this.newMessage.isLoading = false
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    console.log("error-reply", error)
                }
            },
            handleEnterMessage: async function (e) {
                if (e.ctrlKey || e.shiftKey || e.altKey) {
                    this.newMessage.messageText = this.newMessage.messageText + '\n'
                } else {
                    try {
                        this.newMessage.isLoading = true

                        const body = {
                            channelId: this.postDetail.channelId,
                            contentType: "text",
                            postId: this.postDetail._id,
                            message: this.newMessage.messageText
                        }
    
                        const data = await this.sendMessage(e, body.postId, false, this.postDetail.channel, body)

                        this.commentList = [this.formatComment(data), ...this.commentList]
                        this.newMessage.messageText = String()
                        
                        this.updatePostList(body.postId, true)
                        this.newMessage.isLoading = false

                        messageScrollBar.update();
                        const self = this
                        setTimeout(() => {
                            messageScrollBar.scrollTo(0, 0);
                            messageScrollBar.addListener(self.messageScrollBarListener);
                        })
                    } catch (error) {
                        this.newMessage.isLoading = false
                        messageScrollBar.addListener(this.messageScrollBarListener);
                        console.log("error-reply", error)
                    }
                }
            },
            handleEnterComment: async function (e, item) {
                if (e.ctrlKey || e.shiftKey || e.altKey) {
                    item.text = item.text + '\n'
                } else {
                    try {
                        item.isLoading = true
    
                        const body = {
                            channelId: item.channelId || this.postDetail.channelId,
                            contentType: "text",
                            postId: item.postId || this.postDetail._id,
                            message: item.text,
                            from: item.from
                        }

                        const repliedId = item.isChild ? item.parentId : item._id
                        const data = await this.sendMessage(e, repliedId, true, item.channel, body)
                        const commentList = [...this.commentList]
                        const index = commentList.findIndex(e => e._id == repliedId)

                        commentList.splice(index + 1, 0 , { 
                            ...this.formatComment(data), 
                            isChild: true, 
                            isShowReply: true, 
                            sub: Array()
                        })
                        this.commentList = commentList

                        if(item.sub) item.sub.push(data)

                        this.updatePostList(body.postId, item.isReplied)
                        
                        item.isReplied = true
                        item.text = "@"+ item.from.name + " "
                        
                        item.isLoading = false
                    } catch (error) {
                        item.isLoading = false
                        console.log("error-reply-child", error)
                    }
                }
            },
            sendMessage: async function (event, commentId, isChild, channel, body) {
                event.preventDefault();

                const { data } = await axios.post(process.env.VUE_APP_API_URL + `/comments/${commentId}/${channel}`, body, {
                    params: {
                        isChild: isChild
                    }
                })
                
                return data
            },
            updatePostList: function(postId, isReplied) {
                let postList = [...this.postList]
                const index = postList.findIndex(e => e._id == postId)

                if (index >= 0 && index < postList.length) {
                    const [ element ] = postList.splice(index, 1)
                    let post = element

                    post.lastCommentTime = new Date().getTime()
                    post.time = toFriendlyDateTime(new Date().getTime())
                    if(isReplied == false) post.countIsNotReply = post.countIsNotReply && post.countIsNotReply != 0 ? post.countIsNotReply - 1 : 0
                    
                    postList.unshift(post)
                }

                this.postList = postList
            },
            loadPosts: async function () {
                this.postUIFlag.error = Object();
                this.postUIFlag.hasMoreData = true;
                this.postUIFlag.isLoading = true;

                try {
                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/posts', {
                        params: {
                            page: this.postUIFlag.currentPage,
                            sortOrder: this.postUIFlag.sortOrder,
                            sortDir: this.postUIFlag.sortDir,
                            term: this.postUIFlag.searchTerm,
                            size: 25,
                            channelType: 'IG'
                        }
                    });

                    const jsonData = resp.data
                    this.postUIFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.postList = jsonData.content.map(e => {
                        e.time = e.lastCommentTime ? toFriendlyDateTime(e.lastCommentTime) : ""

                        return e
                    });

                    this.postUIFlag.isLoading = false

                    var self = this

                    contactScrollBar.update()
                    setTimeout(() => {
                        contactScrollBar.scrollTo(0, 0);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 250)
                } catch (error) {
                    this.postUIFlag.isLoading = false
                    contactScrollBar.addListener(this.contactScrollBarListener);
                    this.postUIFlag.error = this.$helpers.createError(error)
                }
            },
            loadMorePosts: async function () {
                this.postUIFlag.isLoading = true;
                this.postUIFlag.error = Object();
                this.postUIFlag.currentPage++;

                try {
                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/posts', {
                        params: {
                            page: this.postUIFlag.currentPage,
                            sortOrder: this.postUIFlag.sortOrder,
                            sortDir: this.postUIFlag.sortDir,
                            term: this.postUIFlag.searchTerm,
                            size: 25,
                            channelType: 'IG'
                        }
                    });

                    const jsonData = resp.data
                    this.postUIFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.postUIFlag.isLoading = false
                    
                    // bottom 
                    var scrollView = Scrollbar.get($('#left-inner-scrollbar').get()[0]);
                    
                    this.postList = [...this.postList, ...jsonData.content];

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.offset.y);
                        contactScrollBar.addListener(self.contactScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.postUiFlag.isLoading = false;
                    this.postUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadComments: async function (postId) {
                this.commentUiFlag.isLoading = true
                this.commentUiFlag.error = Object()

                const self = this;

                try {
                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/comments/' + postId, {
                        params: {
                            sortDir: this.commentUiFlag.sortDir,
                            sortOrder: this.commentUiFlag.sortOrder,
                            size: 25
                        }
                    });
                    this.commentUiFlag.isLoading = false;
                    const jsonData = resp.data;
                    this.commentUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;

                    this.commentList = this.formatCommentList(jsonData.content)

                    messageScrollBar.update();

                    setTimeout(() => {
                        messageScrollBar.scrollTo(0, 0);
                        messageScrollBar.addListener(self.messageScrollBarListener);
                    }, 250)

                } catch (error) {
                    this.commentUiFlag.isLoading = false;
                    messageScrollBar.addListener(this.messageScrollBarListener);
                    this.commentUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadMoreComments: async function (postId) {
                this.commentUiFlag.isLoading = true;
                this.commentUiFlag.error = Object();
                this.commentUiFlag.currentPage++;

                try {
                    const resp = await axios.get(process.env.VUE_APP_API_URL + '/comments/' + postId, {
                        params: {
                            page: this.commentUiFlag.currentPage,
                            sortOrder: this.commentUiFlag.sortOrder,
                            sortDir: this.commentUiFlag.sortDir,
                            term: this.commentUiFlag.searchTerm,
                            size: 25
                        }
                    });

                    const jsonData = resp.data
                    this.commentUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.commentUiFlag.isLoading = false

                    // bottom 
                    var scrollView = Scrollbar.get($('.wrapp-chat-content').get()[0]);
                    var currentHeight = scrollView.getSize().content.height;
                    
                    this.commentList = [...this.commentList, ...this.formatCommentList(jsonData.content)];

                    scrollView.update();

                    const self = this

                    setTimeout(() => {
                        scrollView.setPosition(0, scrollView.getSize().content.height - currentHeight, {
                            withoutCallbacks: true
                        });

                        messageScrollBar.addListener(self.messageScrollBarListener);
                    }, 120)

                } catch (error) {
                    this.commentUiFlag.isLoading = false;
                    this.commentUiFlag.error = this.$helpers.createError(error)
                }
            },
            loadChannel: async function (id) {
                try {
                    if(this.selectedChannel._id != id) {
                        const { data } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/' + id);
    
                        this.selectedChannel = data
                    }
                } catch (error) {
                    this.commentUiFlag.error = this.$helpers.createError(error)
                }
            },
            selectPost: async function(id) {
                messageScrollBar.removeListener(this.messageScrollBarListener);
                this.commentList = Array()

                const index = this.postList.findIndex(e => e._id == id)

                let post = this.postList[index]
                // this.postList.splice(index, 1, post)
                this.postDetail = post

                this.commentUiFlag.currentPage = 1;
                this.commentUiFlag.hasMoreData = true;

                await this.loadComments(id)
                await this.loadChannel(post.channelId)
            },
            formatCommentList: function(comments) {
                let tmp = Array()

                comments.forEach(e => {
                    tmp.push(this.formatComment(e))

                    e.sub.reverse().forEach(el => {
                        tmp.push({
                            ...this.formatComment(el),
                            isChild: true
                        })
                    })
                })

                return tmp
            },
            formatComment: function(comment) {
                comment.time = toFriendlyDateTime(comment.createdOn)
                comment.isReply = false
                comment.text = String()
                comment.isShowReply = false
                comment.isLoading = false

                return comment
            },
            resetToEmptyForm: async function() {
                this.postDetail = Object()
                this.commentList = Array()
            },
            loadInitialData: async function () {
                await this.loadPosts();

                if (this.$route.params.id != null) {
                    this.selectPost(decodeURIComponent(this.$route.params.id));
                }
            },
            messageScrollBarListener: function (status) {
                if (this.commentUiFlag.hasMoreData && status.limit.y == status.offset.y) {

                    messageScrollBar.removeListener(this.messageScrollBarListener);
                    this.loadMoreComments(this.postDetail._id);
                }
            },
            contactScrollBarListener: async function(status) {
                if (this.postUIFlag.hasMoreData && status.limit.y == status.offset.y) {
                    const self = this
                    setTimeout(async () => await self.loadMorePosts(), 300);
                }
            },
            chatItemStyle: function (url, channel) {
                if (url) {
                    if (channel == 'cust') {
                        return 'box bg-light-inverse wrapp-chat-img';
                    } else {
                        return 'box bg-light-info wrapp-chat-img';
                    }
                } else {
                    if (channel == 'op') {
                        return 'box bg-light-inverse';
                    } else {
                        return 'box bg-light-inverse';
                    }
                }
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            }
        }

    }
</script>